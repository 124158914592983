import { intersection, isSuperset } from '@/helpers/SetFunctions'

export default class SimulationMatch {
  number = 0
  home = null
  homes = []
  aways = []

  constructor (dto) {
    if (typeof dto === 'string') dto = JSON.parse(dto)
    Object.assign(this, dto)
  }

  get homeSeeds () {
    return [...this.homes, this.home].filter(f => !!f)
  }

  get awaySeeds () {
    return [...this.aways, this.away].filter(f => !!f)
  }

  get allSeeds () {
    return [...this.awaySeeds, ...this.homeSeeds]
  }

  isBetween (ids) {
    const i = new Set(ids)
    if (this.homeSeeds && this.awaySeeds) {
      const h = new Set(this.homeSeeds)
      const a = new Set(this.awaySeeds)
      const ih = intersection(i, h)
      const ia = intersection(i, a)
      return ih.size > 0 && ia.size > 0
    }
    return false
  }

  isWith (ids) {
    const i = new Set(ids)
    if (this.homeSeeds && this.awaySeeds) {
      const h = new Set(this.homeSeeds)
      const a = new Set(this.awaySeeds)
      const ih = isSuperset(h, i)
      const ia = isSuperset(a, i)
      return ih || ia
    }
    return false
  }
}
