<template>
  <div>
    <loading-bar :value="loading"></loading-bar>
    Simulator
    {{seeds}}
    <v-container fluid v-if="!loading" :key="id">
      <v-row dense>
        <v-col cols="12" md="6" lg="4">
          <v-sheet elevation="2">
            <div class="text-center title">As Teammates</div>
            <v-simple-table dense elevation-10>
              <template v-slot:default>
                <thead class="centered">
                  <tr>
                    <th>Team</th>
                    <th
                      v-for="n in seeds"
                      :key="n"
                    >
                      {{n}}
                    </th>
                  </tr>
                </thead>
                <tbody class="centered">
                  <tr
                    v-for="row in seeds"
                    :key="`r${row}`"
                  >
                    <td>{{row}}</td>
                    <td
                      v-for="col in seeds"
                      :key="`c${col}`"
                      :class="getMatchUps(row, col, false) === 0 ? 'grey--text text--lighten-3' : null"
                    >{{ getMatchUps(row, col, false) }}</td>
                  </tr>
                  <tr class="font-weight-bold">
                    <td>Total</td>
                    <td
                      v-for="col in seeds"
                      :key="`c${col}`"
                    >{{ getMatches(col) }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-sheet>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <v-sheet elevation="2">
            <div class="text-center title">As Opponents</div>
            <v-simple-table dense elevation-10>
              <template v-slot:default>
                <thead class="centered">
                  <tr>
                    <th>Team</th>
                    <th
                      v-for="n in seeds"
                      :key="n"
                    >
                      {{n}}
                    </th>
                  </tr>
                </thead>
                <tbody class="centered">
                  <tr
                    v-for="row in seeds"
                    :key="`r${row}`"
                  >
                    <td>{{row}}</td>
                    <td
                      v-for="col in seeds"
                      :key="`c${col}`"
                      :class="getMatchUps(row, col, true) === 0 ? 'grey--text text--lighten-2' : null"
                    >{{ getMatchUps(row, col, true) }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import flatten from '@/helpers/ArrayFlatten'
import firstBy from 'thenby'
import SimulationMatch from '@/classes/SimulationMatch'

export default {
  props: ['pools', 'kob'],
  data () {
    return {
      loading: false,
      templates: [],
      id: 0
    }
  },
  computed: {
    seeds () {
      return [...new Set(flatten(this.pools))].sort(firstBy(Number))
    }
  },
  methods: {
    getMatchTemplates () {
      const counts = [...new Set(this.pools.map(m => m.length))]
      counts.forEach(n => {
        const x = this.templates.find(f => f.poolsOf === n)
        if (!x) {
          this.fetchMatchTemplates(n, this.kob)
        }
      })
    },
    fetchMatchTemplates (teams, kob) {
      this.loading = true
      this.$VBL.tournament.getPoolMatchTemplates(teams, kob)
        .then(r => {
          this.templates.push({
            poolsOf: r.data.poolsOf,
            matches: r.data.matches.map(m => new SimulationMatch(m))
          })
        })
        .catch(e => console.log(e.response))
        .finally(() => {
          this.loading = false
        })
    },
    getMatchUps (row, col, vs) {
      if (row === col) return null
      const pools = this.pools.filter(f => f.includes(row) && f.includes(col))
      const matches = []
      pools.forEach(p => {
        console.log(this.templates)
        const t = this.templates.find(f => f.poolsOf === p.length)
        if (t) matches.push(...t.matches)
      })

      return matches.filter(m => vs ? m.isBetween([row, col]) : m.isWith([row, col])).length
    },
    getMatches (col) {
      if (col) return 0
      const pools = this.pools.filter(f => f.includes(col))
      const matches = []
      pools.forEach(p => {
        console.log(this.templates)
        const t = this.templates.find(f => f.poolsOf === p.length)
        if (t) matches.push(...t.matches)
      })

      return this.matches.filter(m => m.allSeeds.includes(col)).length
    },
    plus () {
      this.id++
    }
  },
  watch: {
    seeds: 'plus',
    pools: 'plus'
  },
  created () {
    this.fetchMatchTemplates(4, true)
    this.fetchMatchTemplates(5, true)
    this.fetchMatchTemplates(6, true)
  }
}
</script>
