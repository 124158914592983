<template>
  <v-card>
    <v-window v-model="window" touchless>

      <v-window-item :key="0">
        <v-card-text>
          <v-alert
            type="error"
            :value="true"
            prominent text
          >
            If you continue you will overwrite the current schedule and lose any matches that have already been played.
          </v-alert>
        </v-card-text>
      </v-window-item>

      <!-- Courts & Time Slots -->
      <v-window-item :key="1">
        <v-card-text>
          <v-row dense>
            <v-col cols="12" sm="6">
              <div class="title">Which courts are you going to use?</div>
              <v-data-table
                :headers="[{ text: 'Courts', value: 'name', sortable: false }]"
                :items="courts"
                hide-default-footer
                show-select
                v-model="selectedCourts"
                item-key="id"
                dense
              >
                <template v-slot:[`header.data-table-select`]="{ props, on }">
                  <v-simple-checkbox
                    color="warning"
                    v-if="props.indeterminate"
                    v-ripple
                    v-bind="props"
                    :value="props.indeterminate"
                    v-on="on"
                  ></v-simple-checkbox>
                  <v-simple-checkbox
                    color="success"
                    v-if="!props.indeterminate"
                    v-ripple
                    v-bind="props"
                    v-on="on"
                  ></v-simple-checkbox>
                </template>
                <template v-slot:[`item.data-table-select`]="{ select, isSelected }">
                  <v-simple-checkbox color="success" :value="isSelected" @input="select($event)"></v-simple-checkbox>
                </template>
                <template v-slot:[`item.name`]="props" v-if="false">
                  <v-edit-dialog
                    :return-value.sync="props.item.name"
                  >
                    {{ props.item.name }}
                    <template v-slot:input>
                      <v-text-field
                        v-model="props.item.name"
                        label="Update Court Name"
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
              </v-data-table>
            </v-col>
            <v-col cols="12" sm="6">
              <div class="title">Which time slots are you using?</div>
              <v-data-table
                :headers="[{ text: 'Slots', value: 'time', sortable: false }]"
                :items="slots"
                hide-default-footer
                show-select
                v-model="selectedSlots"
                item-key="id"
                dense
              >
                <template v-slot:[`header.data-table-select`]="{ props, on }">
                  <v-simple-checkbox
                    color="warning"
                    v-if="props.indeterminate"
                    v-ripple
                    v-bind="props"
                    :value="props.indeterminate"
                    v-on="on"
                  ></v-simple-checkbox>
                  <v-simple-checkbox
                    color="success"
                    v-if="!props.indeterminate"
                    v-ripple
                    v-bind="props"
                    v-on="on"
                  ></v-simple-checkbox>
                </template>
                <template v-slot:[`item.data-table-select`]="{ select, isSelected }">
                  <v-simple-checkbox color="success" :value="isSelected" @input="select($event)"></v-simple-checkbox>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-window-item>

      <!-- Fill Strings -->
      <v-window-item :key="2">
        <v-card-text>
          <div class="d-flex justify-end">
            <v-checkbox
              class="shrink"
              label="I only want to reschedule this round"
              v-model="thisRoundOnly"
              color="success"
            ></v-checkbox>
          </div>
          <div>
            <v-switch label="Auto Generate My Schedule" v-model="autoGen" color="success"></v-switch>
          </div>
          <template v-if="!autoGen">
            <div class="text-end">
              <v-btn
                color="color3"
                text x-small
                @click.stop="dialog = true"
              >
                Quick Add
              </v-btn>
            </div>
            <div class="title">Pools</div>
            <v-container fluid class="pa-0">
              <v-row dense v-if="false">
                <v-col cols="12">
                  <kob-matrix-sim :pools="poolList"></kob-matrix-sim>
                </v-col>
              </v-row>
              <v-row dense v-for="w in weeks" :key="w.roundId">
                <v-col cols="12" class="pa-0 pb-2">
                  <v-card class="pa-3">
                    <div class="font-weight-black">Week {{w.n}} - {{w.date | localDate}}</div>
                    <v-divider></v-divider>
                    <v-row dense>
                      <v-col
                        cols="6" md="4" lg="3"
                        v-for="(p, i) in w.pools"
                        :key="`${w.roundId}-pool-${i}`"
                      >
                        <v-text-field
                          :label="`${p.slot} Court ${p.court}`"
                          v-model="p.seeds"
                          hint="Enter the player's (by seed) for this pool sperated by a comma"
                          persistent-hint
                          color="color3"
                        >
                          <template v-slot:append>
                            <v-fade-transition leave-absolute>
                              <v-icon color="success" v-if="p.seeds && w.valid[i]">fas fa-check-circle</v-icon>
                              <v-icon color="error" v-else-if="p.seeds && !w.valid[i]">fas fa-exclamation-circle</v-icon>
                            </v-fade-transition>
                          </template>
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </template>
        </v-card-text>
      </v-window-item>

      <!-- Match Settings -->
      <v-window-item :key="3">
        <v-card-text>
          <div class="title">Match Settings</div>
          <v-divider></v-divider>
          <v-row>
            <v-col cols="12" md="6" v-for="(setting) in poolSettings" :key="setting.poolsOf">
              <v-card class="elevation-1">
                <v-toolbar dense color="color2 color2Text--text" flat>
                  <v-toolbar-title>Pools of {{setting.poolsOf}}</v-toolbar-title>
                </v-toolbar>
                <v-card-text v-if="getTemplates(setting.poolsOf).length" class="py-0">
                  <v-select
                    :items="getTemplates(setting.poolsOf)"
                    item-text="name"
                    item-value="id"
                    v-model="setting.organizationTemplateId"
                    label="Custom Template"
                    color="color3"
                    item-color="color3"
                  ></v-select>
                </v-card-text>
                <match-settings-editor
                  :setting="setting"
                  :edit="true"
                  :notime="true"
                ></match-settings-editor>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-window-item>

      <!-- complete -->
      <v-window-item :key="4">
        <v-card-text>
          <div class="title">Create Schedule</div>
          <div class="text-center">
            <v-btn
              color="color3 color3Text--text"
              class="ma-5"
              :loading="loading"
              @click.stop="goFill"
            >
              Save & Create Schedule
            </v-btn>
          </div>
        </v-card-text>
      </v-window-item>

    </v-window>
    <v-divider></v-divider>
    <v-card-actions>
      <v-fab-transition>
        <v-btn
          color="color3 color3Text--text"
          fab
          @click.stop="prev"
          :disabled="loading"
        >
          <v-icon>fas fa-caret-left</v-icon>
        </v-btn>
      </v-fab-transition>
      <v-spacer></v-spacer>
      <v-fab-transition>
        <v-btn
          color="success white--text"
          fab
          :key="valid[window]"
          @click.stop="next"
          :disabled="!valid[window] || loading"
        >
          <v-icon>fas fa-caret-right</v-icon>
        </v-btn>
      </v-fab-transition>
    </v-card-actions>

    <v-dialog
      v-model="dialog"
      persistent
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <v-toolbar>KOB Quick Add</v-toolbar>
        <v-card-text>
          <v-text-field
            label="Quick Add String"
            v-model="qas"
            hint="players seperated by commas and pools seperated by pipes"
            persistent-hint
          ></v-text-field>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn color="success" @click.stop="fillQas">go</v-btn>
          <v-btn color="error" @click.stop="resetQas">cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import MatchSettingsEditor from '@/components/Tournament/RoundSettings/MatchSettings.vue'
import MatchSettings from '@/classes/MatchSettings'
import flatten from '@/helpers/ArrayFlatten'
import KobMatrixSim from './KobMatrixSim'

export default {
  props: ['round', 'division'],
  data () {
    return {
      gotit: true,
      window: 1,
      courts: [],
      selectedCourts: [],
      slots: [],
      selectedSlots: [],
      matchCount: 1,
      matchSettings: [],
      teamMatches: [],
      adv: false,
      loading: false,
      selectedRounds: [],
      showRounds: false,
      back2Back: true,
      thisRoundOnly: false,
      weeks: [],
      dialog: false,
      qas: null,
      autoGen: true
    }
  },
  computed: {
    ...mapGetters(['tournament', 'host']),
    advanced () {
      return this.adv
    },
    settings () {
      return this.tournament.leagueSettings
    },
    valid () {
      return [
        this.gotit,
        this.selectedCourts.length > 0 && this.selectedSlots.length > 0,
        this.weeks.filter(f => !f.allValid).length === 0,
        true,
        false
      ]
    },
    poolCount () {
      return this.selectedSlots && this.selectedCourts ? this.selectedSlots.length * this.selectedCourts.length : 0
    },
    pcMatrix () {
      const m = []
      this.selectedSlots.forEach(s => {
        this.selectedCourts.forEach(c => {
          m.push({ slot: s.time, court: c.name, seeds: null, slotId: s.id, courtId: c.id })
        })
      })
      return m
    },
    rounds () {
      return this.thisRoundOnly ? [this.round] : this.division.days.filter(f => f.poolPlay && f.number >= this.round.number)
    },
    roundIds () {
      return this.rounds.map(m => m.id)
    },
    poolsOf () {
      const r = [...new Set(flatten(this.weeks.map(m => m.poolsOf)).filter(f => f !== 0))]
      if (r.length === 0 && this.autoGen) r.push(4)
      return r
    },
    poolSettings () {
      return this.matchSettings.filter(f => this.poolsOf.includes(f.poolsOf))
    },
    dto () {
      return {
        tournamentId: this.tournament.id,
        divisionId: this.division.id,
        matchSettings: this.poolSettings.map(m => m.dto),
        weeks: this.weeks.map(w => w.dto),
        auto: this.autoGen
      }
    },
    // simulator
    poolList () {
      const list = []
      this.weeks.forEach(w => list.push(...w.poolList))
      return list
    }
  },
  methods: {
    fillQas () {
      console.log('here')
      const splt = this.qas.split('|')
      const pools = flatten(this.weeks.map(m => m.pools))
      splt.forEach((p, i) => {
        pools[i].seeds = p
      })
      this.resetQas()
    },
    resetQas () {
      this.qas = null
      this.dialog = false
    },
    useAuto (v) {
      this.autoGen = v
      this.next()
    },
    next () {
      let a = 1
      if (this.window === 2 && !this.thisRoundOnly && this.asd) a = 2
      this.window += a
    },
    prev () {
      if (this.window <= 1) {
        this.init()
        this.$emit('cancel')
        return
      }
      var a = 1
      if (this.window === 4 && !this.thisRoundOnly && this.asd) a = 2
      this.window -= a
    },
    createWeeks () {
      this.weeks = []
      const p = this.pcMatrix
      const seeds = this.division.activeTeams.filter(team => team.seed).map(m => m.seed)
      const teams = this.division.activeTeams
      this.rounds.forEach(r => {
        this.weeks.push({
          roundId: r.id,
          n: r.number,
          date: r.date,
          label: r.name,
          pools: JSON.parse(JSON.stringify(p)),
          get poolList () {
            return this.pools.map(m => m.seeds ? m.seeds.split(',').filter(f => f && !isNaN(f)).map(x => +x) : [])
          },
          get poolTeamIdList () {
            return this.poolList.map(m => m.map(s => {
              const t = teams.find(f => f.seed === s)
              return t ? t.id : 0
            }).filter(f => f > 0)
            )
          },
          get poolsOf () {
            return this.poolList.map(m => m.length)
          },
          get valid () {
            return this.poolList.map(m => {
              const noSeed = m.filter(f => !seeds.includes(f))
              return (noSeed.length === 0 && m.length > 3 && m.length < 7) || m.length === 0
            })
          },
          get allValid () {
            return this.valid.filter(f => !f).length === 0
          },
          get dto () {
            const pl = this.poolTeamIdList
            return {
              roundId: this.roundId,
              props: ['kob', 'isLeague', ...this.pools.map((p, i) => `pool-${i + 1}-${p.slotId}~${p.courtId}`)],
              pools: this.pools.map((p, i) => {
                return {
                  number: i + 1,
                  teamIds: pl[i]
                }
              }).filter(f => f.teamIds.length > 0)
            }
          }
        })
      })
    },
    ensureSettings () {
      const done = this.matchSettings.map(m => m.poolsOf)
      console.log('here')
      this.round && this.round.flight && this.round.flight.settings.forEach(s => {
        if (s.poolsOf) {
          const x = done.includes(s.poolsOf)
          if (!x) {
            const dto = JSON.parse(JSON.stringify(s.dto))
            dto.id = 0
            this.matchSettings.push(new MatchSettings(dto, false, false))
          }
          done.push(s.poolsOf)
        }
      })
      this.poolsOf.forEach(f => {
        const x = done.includes(f)
        if (!x) {
          this.matchSettings.push(new MatchSettings({
            poolsOf: f,
            matchTypeId: 1
          }, true, true))
        }
      })
    },
    init () {
      this.matchCount = 1
      this.selectedRounds = [this.round.id]
      if (this.round.hasMatches) this.window = 0
      this.courts = this.settings.courtNames.map((m, i) => {
        return {
          name: m,
          id: `court-${i}`
        }
      })
      this.selectedCourts = [...this.courts]
      this.slots = this.settings.slots.map((m, i) => {
        return {
          time: m,
          id: `slot-${i}`
        }
      })
      this.selectedSlots = [...this.slots]
      this.createWeeks()
      this.ensureSettings()
    },
    goFill () {
      this.loading = true
      console.log(this.dto)
      this.$VBL.tournament.league.fillPools(this.dto)
        .then(r => this.$emit('complete'))
        .catch(e => console.log('error'))
        .finally(() => {
          this.loading = false
        })
    },
    getTemplates (n) {
      const a = (this.templates || []).filter(f => f.type === 'Pool' && f.template.poolsOf === n)
      if (a.length) a.unshift({ name: 'None', id: null })
      return a
    },
    loadTemplates () {
      this.loading = true
      this.$VBL.organization.templates.get(this.host, 'pool')
        .then(r => {
          this.templates = r.data
        })
        .catch(e => console.log(e.response))
        .finally(() => {
          this.loading = false
        })
    }
  },
  watch: {
    poolCount: 'createWeeks',
    roundIds: 'createWeeks',
    pcMatrix: 'createWeeks',
    poolsOf: 'ensureSettings'
  },
  mounted () {
    this.loadTemplates()
    this.init()
  },
  components: {
    MatchSettingsEditor,
    KobMatrixSim
  }
}
// 1,2,3,4|5,6,7,8|2,5,8,9|3,4,6,7|3,5,7,8|1,4,6,9|1,5,6,9|2,4,7,8|1,3,7,9|2,5,6,8|1,3,6,8|2,4,5,9|3,4,8,9|1,2,5,7|1,4,7,8|2,3,6,9
// 1,2,3,4|5,6,7,8|2,5,8,1|3,4,6,7|3,5,7,8|1,4,6,2|1,5,6,3|2,4,7,8|1,3,7,4|2,5,6,8|1,3,6,8|2,4,5,7|3,2,8,6|1,4,5,7|1,4,7,5|2,3,6,8
</script>
